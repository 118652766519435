import React, { useState } from 'react';
import logo from '../assets/vc_logo_gradient.png'; // Adjust the import path for your logo

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <nav className="sticky top-0 z-50 bg-white border-b border-gray-300">
      <div className="max-w-screen-xl mx-auto p-4 flex flex-wrap items-center justify-between">
        {/* Logo & Brand Name */}
        <a href="/#hero" className="flex items-center space-x-3 rtl:space-x-reverse md:mr-auto">
          <img src={logo} className="h-8" alt="Company Logo" />
        </a>

        {/* Mobile Menu Button */}
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-gray-600 rounded-md md:hidden 
                     hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-multi-level"
          aria-expanded={isNavbarOpen}
          onClick={toggleNavbar}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        {/* Navigation Links */}
        <div
          className={`${isNavbarOpen ? 'block' : 'hidden'} w-full md:block md:w-auto mt-4 md:mt-0`}
          id="navbar-multi-level"
        >
          <ul className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-6 font-semibold text-gray-700">
            <li>
              <a
                href="/#hero"
                className="block px-4 py-2 rounded-full transition-colors duration-200 
                           hover:bg-gray-100 hover:text-gray-900"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/#about"
                className="block px-4 py-2 rounded-full transition-colors duration-200 
                           hover:bg-gray-100 hover:text-gray-900"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="/#services"
                className="block px-4 py-2 rounded-full transition-colors duration-200 
                           hover:bg-gray-100 hover:text-gray-900"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="/#contacts"
                className="block px-4 py-2 rounded-full transition-colors duration-200 
                           hover:bg-gray-100 hover:text-gray-900"
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="/pdpa"
                className="block px-4 py-2 rounded-full transition-colors duration-200 
                           hover:bg-gray-100 hover:text-gray-900"
              >
                PDPA
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;