import React from 'react';
import { FaUsers, FaEye, FaBullseye } from 'react-icons/fa';

const AboutUs = () => {
  return (
    <section className="relative flex flex-col items-center justify-center min-h-screen bg-green-800 text-white px-4 sm:px-6 py-12">
      <div className="relative max-w-5xl w-full text-center md:text-left">
        {/* Main Content */}
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight">
          Connect & Grow.
        </h1>
        <p className="mt-6 text-lg sm:text-xl font-light max-w-lg mx-auto md:mx-0">
          Join a dynamic network of professionals and businesses committed to transforming the future of recruitment and career growth.
        </p>
        
        {/* Info Cards */}
        <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="border border-white rounded-lg p-6 flex flex-col items-center md:items-start text-center md:text-left">
            <FaUsers className="text-4xl mb-4" />
            <h2 className="text-2xl font-semibold">About Us</h2>
            <p className="mt-4 text-lg font-light text-justify">
              Established in 2008, VikConnect is a successful SME focused on HR recruitment. Starting as a headhunting firm, we have evolved into a comprehensive recruitment agency, offering services from permanent placements to contract staffing.
            </p>
          </div>
          <div className="border border-white rounded-lg p-6 flex flex-col items-center md:items-start text-center md:text-left">
            <FaEye className="text-4xl mb-4" />
            <h2 className="text-2xl font-semibold">Vision</h2>
            <p className="mt-4 text-lg font-light text-justify">
              Our vision is to become the premier choice in recruitment solutions, fostering meaningful connections between talent and opportunity, driving success for both individuals and organizations.
            </p>
          </div>
          <div className="border border-white rounded-lg p-6 flex flex-col items-center md:items-start text-center md:text-left">
            <FaBullseye className="text-4xl mb-4" />
            <h2 className="text-2xl font-semibold">Mission</h2>
            <p className="mt-4 text-lg font-light text-justify">
              Our mission is to excel in identifying, attracting, and placing top talent, while consistently providing exceptional service to our clients and candidates.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;