import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Specialisation from './components/Specialisation';
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/About';
import Uen from './components/Uen';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PdpaPage from './components/PdpaPage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <section id="hero">
              <Hero />
            </section>
            <section id="about">
              <About />
            </section>
            <section id="services">
              <Specialisation />
            </section>
            <Services />
            <section id="contacts">
              <Contact />
            </section>
            <section id="uen">
              <Uen />
            </section>
          </>
        } />
        <Route path="/pdpa" element={<PdpaPage />} />
      </Routes>
    </Router>
  );
}

export default App;