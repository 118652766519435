import React from 'react';
import { ReactTyped } from 'react-typed';
import backgroundImage from '../assets/Picture2.png';

const Hero = () => {
  return (
    <section
      className="relative flex flex-col items-center justify-center min-h-screen bg-cover bg-center px-4 sm:px-6 text-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      {/* Content */}
      <div className="relative max-w-4xl w-full text-white">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight">
          Empowering Futures:
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <p className="text-xl sm:text-2xl md:text-3xl font-bold py-2">
              Connecting Talents with
            </p>
            <ReactTyped
              className="text-xl sm:text-2xl md:text-3xl font-bold md:pl-2 pl-2"
              strings={['Opportunities', 'Success', 'Growth']}
              typeSpeed={80}
              backSpeed={90}
              loop
            />
          </div>
        </h1>

        <p className="mt-4 sm:mt-6 text-lg sm:text-xl text-gray-200">
          Discover how VikConnect connects top talent with companies, streamlining the hiring process for lasting success.
        </p>

        {/* Call-to-Action Buttons */}
        <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a
            href="#services"
            className="inline-block px-5 sm:px-6 py-2 sm:py-3 text-lg font-medium text-white border border-white rounded-full hover:bg-gray-100 hover:text-black transition-colors"
          >
            Our Services
          </a>
          <a
            href="#contacts"
            className="inline-block px-5 sm:px-6 py-2 sm:py-3 text-lg font-medium text-white bg-green-700 rounded-full hover:bg-green-600 transition-colors"
          >
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;