import React from 'react';
import { FaBriefcase, FaCog, FaChartLine } from 'react-icons/fa';

const Specialisation = () => {
  return (
    <section className="relative flex flex-col items-center justify-center min-h-[50vh] bg-gray-100 text-gray-900 px-12 py-16 text-center">
      <div className="max-w-6xl w-full">
        {/* Main Content */}
        <h1 className="text-5xl font-bold tracking-tight">
          Our Specialisation
        </h1>
        {/* <p className="mt-6 text-xl font-light max-w-lg"> */}
        <p className="mt-6 text-xl font-light max-w-3xl mx-auto text-center">
          Identifying and placing top-tier talent across diverse industries. We are committed to delivering high-quality recruitment solutions tailored to the evolving needs of businesses and professionals.
        </p>
        
        {/* Stats Grid */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* <div>
            <h2 className="text-6xl font-bold">1500+</h2>
            <p className="text-lg font-light">Successful Placements</p>
          </div>
          <div>
            <h2 className="text-6xl font-bold">15+</h2>
            <p className="text-lg font-light">Years of Experience</p>
          </div> */}
        </div>
    

        {/* Specialisation Grid */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center">
            <FaBriefcase className="text-green-700 text-4xl mb-4" />
            <h2 className="text-2xl font-semibold">Information Technology</h2>
            <p className="mt-2 text-lg font-light">Expertise in software development, cybersecurity, data science, cloud computing, and IT infrastructure.</p>
          </div>
          <div className="flex flex-col items-center">
            <FaCog className="text-green-700 text-4xl mb-4" />
            <h2 className="text-2xl font-semibold">Engineering</h2>
            <p className="mt-2 text-lg font-light">Civil, mechanical, electrical, and software engineering across various industries.</p>
          </div>
          <div className="flex flex-col items-center">
            <FaChartLine className="text-green-700 text-4xl mb-4" />
            <h2 className="text-2xl font-semibold">General Recruitment</h2>
            <p className="mt-2 text-lg font-light">Sourcing top talent across multiple industries, ensuring the right fit for businesses.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Specialisation;
