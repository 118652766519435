const Uen = () => {
    return (
      <section style={styles.infoSection}>
        UEN: 200804439G / GST No: 200804439G / EA Licence No: 09C5380
      </section>
    );
  };
  
  /* Inline CSS-in-JS for convenience. Adapt as needed. */
  const styles = {
    appContainer: {
      margin: 0,
      fontFamily: 'Arial, sans-serif',
      color: '#333',
    },
    header: {
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    title: {
      margin: 0,
      fontSize: '24px',
    },
    address: {
      textAlign: 'right',
      fontSize: '14px',
      lineHeight: '1.5',
      marginTop: '5px',
    },
    contactDetails: {
      fontSize: '14px',
      margin: '20px',
    },
    infoSection: {
      backgroundColor: '#e9ecef', // or your preferred grey
      padding: '15px',
      textAlign: 'center',
      fontSize: '14px',
      marginTop: '20px',
    },
  };
  
  export default Uen;