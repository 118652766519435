import React from 'react';
// import logo from '../assets/vikconnect_logo.jpg';

const ContactPage = () => {
  return (
    <section className="relative flex flex-col items-center justify-center min-h-[20vh] bg-white text-gray-700 px-4 sm:px-12 py-6">
      <div className="max-w-6xl w-full flex flex-col md:flex-row justify-between items-center text-center md:text-left">
        {/* Left Section - Title */}
        <div className="flex flex-col items-center md:items-start">
          {/* <img src={logo} alt="VikConnect Logo" className="h-12 w-auto" /> */}
          <span className="text-3xl sm:text-4xl font-bold"> VikConnect | Connect with Us</span>
        </div>

        {/* Right Section - Contact Details */}
        <div className="text-center md:text-right mt-6 md:mt-0">
          <h2 className="text-lg font-semibold">Address</h2>
          <p className="text-md">2 Venture Drive #08-06 Vision Exchange, Singapore 608526</p>
          
          <h2 className="text-lg font-semibold mt-4">Contact Details</h2>
          <p className="text-md">Email: jobs@vikconnect.com</p>
        </div>        
      </div>
    </section>
  );
};

export default ContactPage;