import React from 'react';
import serviceImage from '../assets/Picture3.png';

const ServicesPage = () => {
  return (
    <section className="relative flex flex-col items-center justify-center min-h-[50vh] bg-green-800 text-white px-6 sm:px-12 py-12 sm:py-16">
      <div className="max-w-6xl w-full">
        {/* Two-Column Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12 items-center">
          <div className="text-center md:text-left max-w-3xl">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight">Our Services</h1>
            <p className="mt-4 text-lg sm:text-xl font-light border-b border-gray-300 pb-4 text-justify">
              Permanent placement and contract staffing, tailored to your needs. At VikConnect, we facilitate seamless transitions for both employers and job seekers.
            </p>
            <h2 className="text-xl sm:text-2xl font-semibold mt-8">Permanent Placement</h2>
            <p className="mt-4 text-lg sm:text-xl font-light border-b border-gray-300 pb-4 text-justify">
              We offer permanent placement solutions, ensuring the perfect match between exceptional talent and permanent positions.
            </p>
            <h2 className="text-xl sm:text-2xl font-semibold mt-8">Contract Staffing</h2>
            <p className="mt-4 text-lg sm:text-xl font-light border-b border-gray-300 pb-4 text-justify">
              Our expertise extends to contract staffing, providing flexible solutions to meet short-term project needs or to cover temporary gaps in your workforce.
            </p>
          </div>
          <div className="w-full h-auto md:h-full">
            <img src={serviceImage} alt="Our Services" className="w-full h-auto md:h-full object-cover shadow-lg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesPage;